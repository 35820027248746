import moment from 'moment'
import md5 from 'md5'

export function getSign () {
  const timestamp = moment().unix()
  const salt = process.env.VUE_APP_REQUEST_SALT
  const sign = md5(timestamp + salt)
  return { timestamp, sign }
}
export function getElementPosition(e) {
  let x = 0;
  let y = 0;
  while (e != null) {
    x += e.offsetLeft;
    y += e.offsetTop;
    e = e.offsetParent;
  }
  return { x, y };
}
/**
 * 将指定元素滚动到指定位置
 * @param {String} dom css选择
 */
export const scrollTo = (dom,top) => {
  const scrollDom = document.querySelector(dom)
  setTimeout(() => {
    scrollDom.scrollTo({
      top,
      behavior: 'smooth'
    })
  })
}
export const isArray = (value) => toString.call(value) === '[object Array]'
